import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ExternalLink } from './SVG';
import {
  Text,
  Stack,
  devices,
  WhiteButton,
  BlueButtonBasic,
} from '../components/common';
import Modal from './Modal';
import { Employee } from '../@types/types';
import Markdown from './Markdown';

type Props = {
  employeeData: Employee;
};

const ProfileCard = ({ employeeData }: Props) => {
  const {
    Name,
    Picture,
    Description,
    ReservationLink: link,
    Title,
  } = employeeData;

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((showModal) => !showModal);
  };

  return (
    <Container>
      <ContentContainer>
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          employeeData={employeeData}
        />
        <Image src={Picture.publicURL} alt={Name} />
        <ProfileDetails>
          <NameAndTitle>
            <NameText variant={'subtitle'}>{Name}</NameText>
            <TitleText variant={'body'}>{Title}</TitleText>
          </NameAndTitle>

          {Description && (
            <DescriptionSection>{Description}</DescriptionSection>
          )}

          <ExternalLinks>
            {link && (
              <Button href={link}>
                Varaa aika
                <CustomIcon fill={'#fff'} />
              </Button>
            )}

            <ModalButton onClick={openModal} linkExist={link}>
              Lisätietoa
            </ModalButton>
          </ExternalLinks>
        </ProfileDetails>
      </ContentContainer>
    </Container>
  );
};

export default ProfileCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
  align-items: center;
  padding-bottom: 80px;

  @media ${devices.tablet} {
    flex: 0 0 50%;

    &:last-child {
      padding-bottom: 0px;
    }
  }
`;

const ContentContainer = styled.div`
  width: 300px;

  @media ${devices.tablet} {
    width: 100%;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  height: 300px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;

  @media ${devices.mobileS} {
    height: 200px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ProfileDetails = styled(Stack)`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing.medium};
  text-align: center;

  @media ${devices.mobileXL} {
    margin: auto;
  }
`;

const NameText = styled(Text)`
  font-size: ${(props) => props.theme.sizing.medium};

  @media ${devices.tablet} {
    text-align: center;
  }
`;

const TitleText = styled(Text)`
  font-style: italic;
  font-size: calc((${(props) => props.theme.sizing.default}) + 1px);

  @media ${devices.tablet} {
    text-align: center;
  }
`;

const DescriptionSection = styled(Markdown)`
  line-height: 20px;

  @media ${devices.tablet} {
    text-align: center;
  }
`;

const ExternalLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

const CustomIcon = styled(ExternalLink)`
  margin-left: 10px;
`;
const NameAndTitle = styled.div`
  @media ${devices.mobileXL} {
    margin-top: ${(props) => props.theme.sizing.default};
  }
`;

const Button = styled(BlueButtonBasic)`
  @media ${devices.tablet} {
    width: 80%;
    max-width: 300px;
    margin: ${(props) => props.theme.sizing.default} 0px;
    justify-content: space-between;
  }
`;

const ModalButton = styled(WhiteButton)<{ linkExist: string }>`
  cursor: pointer;
  margin: ${(props) => !props.linkExist && 'auto'};

  @media ${devices.tablet} {
    width: 80%;
    max-width: 300px;
  }
`;
